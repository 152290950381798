'use client';

import React, { useEffect } from 'react';
import Head from 'next/head';
import styled from 'styled-components';
import responsive from '@miniintern/styles/responsive';
import { Button } from '@miniintern/ui';
import Link from 'next/link';
import routes from '#constants/routes';
import { sendErrorToSentry } from '#lib/error';

const Page404Block = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;

  .not-found-image {
    width: 100%;
  }

  .not-found-text-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    top: -100px;

    .not-found-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
    }

    .not-found-desc {
      font-size: 14px;
      margin-bottom: 32px;
    }
  }

  @media (max-width: ${responsive.medium}) {
    height: 80vh;

    .not-found-image {
      margin: 10px 0 10px;
    }

    .not-found-text-box {
      top: 0;

      .not-found-title {
        font-size: 14px;
        margin-bottom: 10px;
      }

      .not-found-desc {
        font-size: 12px;
      }
    }
  }

  @media (max-width: ${responsive.small}) {
    .not-found-image {
      margin: 40px 0 60px;
    }
  }
`;

const Page404: React.FC = () => {
  useEffect(() => {
    sendErrorToSentry(new Error('[404] Page Not Found'));
  }, []);

  return (
    <>
      <Head>
        <title>Page not found | 미니인턴</title>
      </Head>
      <Page404Block>
        <div className="visible-on-pc">
          <img
            src={'/static/images/404/404-pc.png'}
            alt="미니인턴 404"
            className="not-found-image"
          />
        </div>
        <div className="visible-on-mobile">
          <img
            src={'/static/images/404/404-mobile.png'}
            alt="미니인턴 404"
            className="not-found-image"
          />
        </div>

        <div className="not-found-text-box">
          <p className="not-found-title">죄송합니다. 해당 페이지를 찾을 수 없습니다.</p>
          <p className="not-found-desc">
            바쁘게 일하다 그만 잃어버린 페이지인가봐요.
            <br /> 첫페이지로 돌아가거나 미니인턴에 문의해 주세요.
          </p>

          <Link href={routes.home} prefetch={false}>
            <Button buttonType="line" size="medium" color="blue_500">
              홈으로 이동
            </Button>
          </Link>
        </div>
      </Page404Block>
    </>
  );
};

export default Page404;
